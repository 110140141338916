import { useEffect, useReducer } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "./GlobalContext.jsx";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import HomeIcon from "../assets/icons/home.png";
import StudentsIcon from "../assets/icons/students.png";
import LecturesIcon from "../assets/icons/lectures.png";
import CodesIcon from "../assets/icons/codes.png";
import AttendanceIcon from "../assets/icons/attendance.png";
import GroupIcon from "../assets/icons/groups.png";
import ExamsIcon from "../assets/icons/exams.png";
import RequestsIcon from "../assets/icons/requests.png";
import HelpIcon from "../assets/icons/help.png";
import ZoomIcon from "../assets/icons/zoom.png";
import WhatsappIcon from "../assets/icons/whatsapp.png";
import ReviewsIcon from "../assets/icons/reviews.png";
import { Dropdown } from "antd";

function MainLayout({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();

  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate(admin ? "/admin/signin" : "/signin");
  }

  const items = [
    {
      label: <Link to={"/admin/profile"}>Profile</Link>,
      key: "1",
    },
    {
      label: <button onClick={handleSignOut}>Logout</button>,
      key: "2",
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 900) {
      dispatch({
        type: "SetCollapse",
      });
    }
  }, []);

  return (
    <section className="flex flex-row-reverse h-screen max-h-screen w-full overflow-hidden bg-white dark:bg-dark dark:text-light md:pt-32">
      <section className="fixed-navbar fixed inset-0 top-0 z-50  flex h-[70px] w-full items-center justify-between bg-white px-16 text-light">
        <div className="flex items-center">
          <div className="collapse-btn-wrapper relative mr-4">
            <button
              onClick={() =>
                dispatch({
                  type: "SetCollapse",
                })
              }
              className="text-[20px] text-secondary"
            >
              {state.collapse ? <MdClose /> : <GiHamburgerMenu />}
            </button>
            {/* <FontAwesomeIcon
              onClick={() =>
                dispatch({
                  type: "SetCollapse",
                })
              }
              className={` absolute right-0 top-20 z-50  translate-x-4 rotate-180 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
                state.collapse && "rotate-0"
              }`}
              icon={faCircleChevronRight}
            /> */}
          </div>
          <div className="logo h-fit">
            <img
              className="h-16 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
              onClick={() => navigate("/admin/dashboard")}
              src="https://api.ahmedgamal.online/uploads/assets/logo-dash-light.png"
              alt="logoDark"
            />
          </div>
        </div>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <button>
            <img
              src={
                admin
                  ? "https://api.ahmedgamal.online/uploads/assets/logo-dash-light.png"
                  : `https://api.ahmedgamal.online/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`
              }
              className="w-14"
              alt="profile"
            />
          </button>
        </Dropdown>
      </section>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-[calc(100vh-70px)] w-full overflow-y-auto relative top-[70px]  md:top-0 bg-mainBackground">
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder reletive h-full ${
          state.collapse ? "w-0" : "w-1/5 min-w-[300px]"
        }  transition-all duration-200 ease-in-out md:hidden`}
      ></div>
      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed top-[60px]  z-40 ${
          state.collapse ? "left-[-300px]" : "left-0 w-1/5 min-w-[300px] "
        } fixed left-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : ""
                    }`}
      >
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-between gap-10 overflow-y-auto bg-white py-10">
          {/* <div className="logo h-fit md:hidden">
            <img
              className={` ${
                state.collapse ? "h-32" : "h-48"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/")}
              src="https://api.ahmedgamal.online/uploads/assets/logo-dash-light.png"
              alt="logo-dash-light"
            />
          </div> */}

          {admin && (
            <ul className="dash-menu-items flex h-fit w-full  flex-col items-center gap-5 ">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.length > 0) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/dashboard"
                    className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "justify-bet items-center  px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={HomeIcon} alt="" className={` w-8`} />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Home
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("student")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/online-students"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={StudentsIcon} alt="" className={` w-8`} />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Online Students
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("student")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/center-students"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={StudentsIcon} alt="" className={` w-8`} />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Center Students
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/codes"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={CodesIcon} alt="" className={` w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Codes
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/codes-tracker"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={HomeIcon} alt="" className={` w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Code tracking
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("attendance")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/attendance"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={AttendanceIcon} alt="" className={` w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Attendance
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("lecture")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/lectures"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={LecturesIcon} alt="" className={` w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Lectures
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("exam")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/exams"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={ExamsIcon} alt="" className={` w-8`} />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Exams
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("questionbank")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/question-bank"
                  className={`group flex py-4 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <FontAwesomeIcon
                      className=" w-8"
                      icon={faClipboardQuestion}
                    />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Question Bank
                    </p>
                  </div>
                </NavLink>
              </li>
            )} */}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("group")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/groups"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={GroupIcon} alt="" className={`w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Groups
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("request")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/requests"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={RequestsIcon} alt="" className={` w-8`} />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Requests
                      </p>
                    </div>

                    {NumbersInTabs?.data?.requests_count &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("request_accept")
                        )) && (
                        <span
                          className={`numbers flex items-center  justify-center rounded-full p-1  ${
                            state.collapse
                              ? "mb-5 h-3   w-3  bg-secondary text-[0px]"
                              : "h-10  w-10 min-w-[25px] min-h-[25px]  text-2xl"
                          }  text-secondary transition-all duration-200 ease-in-out group-hover:bg-light amd:mb-5 amd:h-3 amd:w-3  amd:min-w-[8px] amd:min-h-[8px] amd:bg-secondary amd:text-[0px] md:mb-0 md:h-10 md:w-10 md:min-w-[25px] md:min-h-[25px] md:bg-transparent md:text-2xl`}
                        >
                          {NumbersInTabs?.data?.requests_count}
                        </span>
                      )}
                  </NavLink>
                </li>
              )}

              {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("user")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/assistants"
                  className={`group flex py-4 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <FontAwesomeIcon className="w-8" icon={faUserGear} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Assistants
                    </p>
                  </div>
                </NavLink>
              </li>
            )} */}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("whatsapp_show")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/whatsapp"
                    className={`group flex py-4 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center gap-5">
                      <img src={WhatsappIcon} alt="" className={` w-8`} />

                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        WhatsApp
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/help"
                  className={`group flex py-4 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <img src={HelpIcon} alt="" className={` w-8`} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Help
                    </p>
                  </div>
                </NavLink>
              </li>
              {/* <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="flex h-full w-full justify-center"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                to="/admin/reviews"
                className={`group flex py-4 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div className="text-icon  flex w-full items-center gap-5">
                  <img src={ReviewsIcon} alt="" className={` w-8`} />
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    Reviews
                  </p>
                </div>
              </NavLink>
            </li>
            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="flex h-full w-full justify-center"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                to="/admin/zoom"
                className={`group flex py-4 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div className="text-icon  flex w-full items-center gap-5">
                  <img src={ZoomIcon} alt="" className={` w-8`} />
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    Zoom
                  </p>
                </div>
              </NavLink>
            </li> */}
            </ul>
          )}

          {!admin && (
            <ul className="dash-menu-items flex h-fit w-full  flex-col items-center gap-5 ">
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/home"
                  className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <img src={HomeIcon} alt="" className={` w-8`} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Home
                    </p>
                  </div>
                </NavLink>
              </li>
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/lectures"
                  className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <img src={LecturesIcon} alt="" className={` w-8`} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Lectures
                    </p>
                  </div>
                </NavLink>
              </li>
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/exams"
                  className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <img src={ExamsIcon} alt="" className={` w-8`} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Exams
                    </p>
                  </div>
                </NavLink>
              </li>
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/profile"
                  className={`group flex  h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }   transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center gap-5">
                    <img src={ExamsIcon} alt="" className={` w-8`} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Profile
                    </p>
                  </div>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
    </section>
  );
}

export default MainLayout;

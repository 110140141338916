import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"

const QuestionAnswer = ({ showQuestion, setShowQuestion, answeredQuestions, currentSelectedIndex }) => {
  const currentQuestion = answeredQuestions[currentSelectedIndex]
  const correctAnswer = currentQuestion?.correct_answer
  const studentAnswer = currentQuestion?.student_answer
  return (
    <div
      className={`single-question fixed left-0 top-0  z-40 h-screen w-[37.5%] overflow-y-auto bg-blue-200 px-[20px] pb-[40px] pt-[150px] transition-all  duration-300 ease-in-out md:w-full ${
        showQuestion ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="close-btn-wrapper mb-[20px] flex w-full justify-end pr-[20px]">
        <div
          onClick={() => setShowQuestion(false)}
          className="close-question-btn  flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full  bg-accent text-light shadow-md shadow-black/40 transition-all duration-300 ease-in-out hover:scale-110 active:scale-90"
        >
          <FontAwesomeIcon
            className={`transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 `}
            icon={faXmark}
          />
        </div>
      </div>

      <div className="current-question flex flex-row-reverse">
        {`.${currentSelectedIndex + 1}`}
        <div
          dangerouslySetInnerHTML={{
            __html: currentQuestion?.question,
          }}
        />
      </div>
      <div className="input-container response-question-input flex flex-col items-end"></div>
      <div className="response-question-answers">
        {currentQuestion?.answers.map((answer, i) => (
          <div
            key={i}
            className={`${correctAnswer !== studentAnswer && answer.order === studentAnswer && "response-selected"}
            ${studentAnswer === correctAnswer && answer.order === correctAnswer && "bg-lime-500 border-lime-500"}
            ${answer.order === correctAnswer && "bg-lime-500 border-lime-500"} `}
          >
            <span>{answer.order}</span>
            <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuestionAnswer

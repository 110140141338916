//!you may need to change record.key to record.key
import { Popconfirm, Table, Form, Input, Tag, Tooltip } from "antd";
import { useReducer, useEffect } from "react";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "./useWindowSize";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../GlobalContext.jsx";

function useTable(
  tableFor,
  nested,
  sessionID,
  dependency1,
  dependency2,
  params,
  ifExternal,
  externalData,
  refetch,
  setReFetch
) {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();

  const navigate = useNavigate();
  const { width } = useWindowSize();
  useEffect(() => {
    if (width && width < 800) {
      dispatch({ type: "setSmallWindow", payload: true });
    } else {
      dispatch({ type: "setSmallWindow", payload: false });
    }
  }, [width]);

  let columns;
  let api;

  switch (tableFor) {
    case "codes":
      api = process.env.REACT_APP_ALL_CODES_API;
      break;
    case "groups":
      api = process.env.REACT_APP_ALL_GROUPS_API;
      break;
    case "QuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    // TODO: change API
    case "studentQuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    case "attendance":
      api = process.env.REACT_APP_ADMIN_ATTENDANCE_TAB_API;
      break;

    case "assistants":
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;

    case "onlineStudents":
      api = `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${params}`;
      break;
    case "centerStudents":
      api = `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${params}`;
      break;
    case "materials":
      api = `${process.env.REACT_APP_ADMIN_ALL_MATERIALS_API}${params}`;
      break;
    case "homeworkSets":
      api = `${process.env.REACT_APP_ADMIN_HOMEWORK_SETS_API}${params}`;
      break;
    case "quizSets":
      api = `${process.env.REACT_APP_ADMIN_QUIZ_SETS_API}${params}`;
      break;
    case "examSets":
      api = `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${params}`;
      break;
    case "sessionAbsence":
      api = `${process.env.REACT_APP_ADMIN_SESSICONS_ABSENCE_API}${params.groupID}/${params.lectureID}`;
      break;

    case "lectures":
      api = `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${params}`;
      break;
    case "purchaseHistory":
      api = `${process.env.REACT_APP_EDIT_STUDENT_PURCHASE_HISTORY_API}/${params}`;
      break;
    case "viewStudentExam":
      api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
      break;

    default:
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;
  }

  function reducer(state, action) {
    switch (action.type) {
      case "setData": {
        return {
          ...state,
          data: action.payload,
        };
      }
      case "setForFilterData": {
        return {
          ...state,
          forFilterData: action.payload,
        };
      }

      case "SetLoading": {
        return {
          ...state,
          loading: action.payload,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: !state.showSidebar,
        };
      }

      case "SetEditRowKey": {
        return {
          ...state,
          editRowKey: action.payload,
        };
      }
      case "SetEditableQuizKey": {
        return {
          ...state,
          editableQuizKey: action.payload,
        };
      }
      case "SetSortingOptions": {
        return {
          ...state,
          sortingOptions: action.payload,
        };
      }
      case "SetQuery": {
        return {
          ...state,
          query: action.payload,
        };
      }
      case "SetColumnQuery": {
        return {
          ...state,
          columnQuery: action.payload,
        };
      }
      case "SetQueriedColumn": {
        return {
          ...state,
          queriedColumn: action.payload,
        };
      }
      case "SetFilteredInfo": {
        return {
          ...state,
          filteredInfo: action.payload,
        };
      }
      case "setSelectedRows": {
        return {
          ...state,
          selectedRows: action.payload,
        };
      }
      case "setSmallWindow": {
        return {
          ...state,
          smallWindow: action.payload,
        };
      }
      case "setUpdatingType": {
        return {
          ...state,
          updatingType: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const initialState = {
    data: [],
    forFilterData: [],
    loading: false,
    editRowKey: "",
    editableQuizKey: "",
    sortingOptions: {},
    query: "",
    columnQuery: "",
    queriedColumn: "",
    filteredInfo: {},
    selectedRows: [],
    smallWindow: false,
    updatingType: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchData() {
    if (ifExternal) {
      dispatch({ type: "setData", payload: externalData });
      dispatch({ type: "setForFilterData", payload: externalData });
      //console.log(externalData);

      dispatch({ type: "SetLoading", payload: false });
    } else {
      try {
        const res = await axios.get(api, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              AuthState.userData.student_token || AuthState.userData.admin_token
            }`,
            /* Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`, */
            //"Content-Type": "multipart/form-data",
          },
          //withCredentials: true,
        });
        const data = await res.data;

        dispatch({ type: "setData", payload: data.data });
        dispatch({ type: "setForFilterData", payload: data.data });

        dispatch({ type: "SetLoading", payload: false });
      } catch (error) {
        toast.error(
          error?.response?.data?.message ||
            "An error occurred while loading data"
        );

        dispatch({ type: "SetLoading", payload: false });
        //!need to handle the case of delete the last item in the table and refetching again, so the refetch request will response with error no data and the last data will stay in the page but it must be removed whereas the item has deleted
        dispatch({ type: "setData", payload: [] });
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "SetLoading", payload: true });

    fetchData();
  }, [dependency1, dependency2, externalData]);
  const [form] = Form.useForm();

  async function handleDelete(record) {
    let deleteApi;
    switch (tableFor) {
      case "codes":
        deleteApi = process.env.REACT_APP_DELETE_CODE_API;
        break;
      case "groups":
        deleteApi = process.env.REACT_APP_DELETE_GROUP_API;
        break;
      case "QuestionBank":
        deleteApi = process.env.REACT_APP_DELETE_CATEGORY_API;
        break;
      case "attendance":
        deleteApi = "https://groups-api.onrender.com/data";
        break;

      case "assistants":
        deleteApi = process.env.REACT_APP_DELETE_ASSISTANT_API;
        break;
      case "sessionAttendance":
        deleteApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_TABLE_DELETE_ROW_API;
        break;

      case "onlineStudents":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_API;
        break;
      case "centerStudents":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_API;
        break;
      case "materials":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_MATERIAL_API;
        break;
      case "homeworkSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "quizSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "examSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "viewStudentExam":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_TRIAL_EXAM_API;
        break;
      case "lectures":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_SESSION_API;
        break;

      default:
        deleteApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: deleteApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "onlineStudents" || tableFor === "centerStudents") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  async function handleBlock(record) {
    let deleteApi;
    switch (tableFor) {
      case "onlineStudents":
        deleteApi = process.env.REACT_APP_ADMIN_BLOCK_STUDENT_API;
        break;
      case "centerStudents":
        deleteApi = process.env.REACT_APP_ADMIN_BLOCK_STUDENT_API;
        break;

      default:
        deleteApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: deleteApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "onlineStudents" || tableFor === "centerStudents") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  async function handleRestore(record) {
    const options = {
      url: process.env.REACT_APP_ALL_DELETED_STUDENTS_RESTORE_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        fetchData();
        AuthDispatch({
          type: "setRefetchIfAcceptOrDeleteRequest",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Something Wrong has Happened!"
      );
    }
  }
  /**
//!we check if the current id stored in the editRowKey is equal to the record id to know which row is going to be updated
 */

  function isEditing(record) {
    return record.key === state.editRowKey;
  }

  /**
//!here we reset the editRowKey to indicate that no row is going to be update and hide the cancel and save buttons then show the edit and delete buttons again 
 */

  async function handleUpdateRequest(data) {
    let editApi;
    let finalData = data;

    if (tableFor === "sessionAttendance") {
      if (state.updatingType === "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_QUIZ_DEGREE_API;
        const { id, student_quiz_degree } = data;
        finalData = { id: id, degree: student_quiz_degree };
      } else if (state.updatingType !== "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_HOMEWORK_API;
      }
    } else {
      switch (tableFor) {
        case "codes":
          editApi = process.env.REACT_APP_EDIT_CODE_API;
          break;
        case "groups":
          editApi = process.env.REACT_APP_EDIT_GROUP_API;
          break;
        case "QuestionBank":
          editApi = process.env.REACT_APP_EDIT_CATEGORY_API;
          break;
        case "attendance":
          editApi = "https://groups-api.onrender.com/data";
          break;

        case "assistants":
          editApi = process.env.REACT_APP_ALL_ASSISTANTS_API;
          break;
        case "homeworkSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "quizSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "examSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "lectures":
          editApi = process.env.REACT_APP_ADMIN_EDIT_STUDENT_SESSION_API;
          break;

        default:
          editApi = "https://students-api-g0pp.onrender.com/data";
          break;
      }
    }
    const options = {
      url: editApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: finalData,
    };
    const res = await axios(options);
    return await res.data;
  }
  function handleCancelEdit() {
    dispatch({ type: "SetEditRowKey", payload: "" });
  }
  //!  value is for homework update from radio button ----
  async function handleSaveEdit(record, value) {
    //console.log(record);
    const key = record.key;

    try {
      const row = value ? { homework: value } : await form.validateFields();
      //console.log("row", row);
      const res = await handleUpdateRequest({ ...row, id: key });
      toast.success(res.message);
      dispatch({ type: "SetEditRowKey", payload: "" });
      dispatch({ type: "SetEditableQuizKey", payload: "" });
      dispatch({ type: "setUpdatingType", payload: "" });

      if (ifExternal) {
        setReFetch(!refetch);
      } else {
        fetchData();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  //! -- to prevent sending request from onBlur of all editable inputs ---
  function handleUpdateQuiz(record) {
    if (state.editableQuizKey === record.key) {
      handleSaveEdit(record);
    }
  }
  function handleUpdateHomework(record, value) {
    handleSaveEdit(record, value);
  }

  function handleQuizDoubleClicked(record) {
    dispatch({ type: "SetEditableQuizKey", payload: record.key });
    dispatch({ type: "setUpdatingType", payload: "quiz" });

    handleEdit(record);
  }
  function handleEdit(record) {
    form.setFieldsValue({
      /* firstname: "",
      username: "",

      password: "",
      governorate: "",
      city: "",
      academiclevel: "",
      school: "",
      activationcode: "", */
      ...record,
    });

    dispatch({ type: "SetEditRowKey", payload: record.key });
  }
  function handleResetSortFilterSearch(record) {
    dispatch({ type: "SetSortingOptions", payload: {} });
    dispatch({
      type: "SetFilteredInfo",
      payload: {},
    });
    dispatch({
      type: "SetQuery",
      payload: "",
    });
    fetchData();
  }

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            onBlur={() => handleUpdateQuiz(record)}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  //!to save the sorting options and retrieve then reset it from reset button

  function handleTableChange(_, filter, sorter) {
    const { order, field } = sorter;
    dispatch({
      type: "SetSortingOptions",
      payload: { columnKey: field, order },
    });
    dispatch({
      type: "SetFilteredInfo",
      payload: filter,
    });
  }

  function handleGlobalSearch(e) {
    dispatch({ type: "SetQuery", payload: e.target.value });

    const filteredData = state?.forFilterData?.filter((item) => {
      return (
        Object.values(item)[0]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase()) ||
        Object.values(item)[1]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[2]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[3]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[4]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[5]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[6]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[7]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[8]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[9]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[10]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[11]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[12]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[13]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[14]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });

    dispatch({ type: "setData", payload: filteredData });
  }

  function handleColumnSearch(selectedKeys, confirm, dataIndex) {
    confirm({ closeDropdown: false });
    dispatch({ type: "SetColumnQuery", payload: selectedKeys[0] });
    dispatch({ type: "SetQueriedColumn", payload: dataIndex });
  }
  function handleColumnReset(clearFilters, confirm) {
    clearFilters();
    dispatch({ type: "SetColumnQuery", payload: "" });
    dispatch({ type: "SetQueriedColumn", payload: "" });
    confirm();
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="flex h-40 w-72 flex-col justify-center gap-5 bg-light p-4">
        <Input
          className="signin-inputs search-table-placeholder text- h-10 pl-4 placeholder:!text-xl"
          autoFocus
          placeholder={` البحث في ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleColumnSearch(selectedKeys, confirm, dataIndex);
            }
          }}
        />
        <div className="flex justify-center gap-5 ">
          <button
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
          >
            بحث
          </button>
          <button
            onClick={() => handleColumnReset(clearFilters, confirm)}
            className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
          >
            Delete
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FontAwesomeIcon
        className={`${filtered ? "text-secondary" : "text-save"}`}
        icon={faMagnifyingGlass}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      state.queriedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#f9b522", padding: 0 }}
          searchWords={[state.columnQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  async function handleRetakeExam(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_RETAKE_TRIAL_EXAM_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "onlineStudents" || tableFor === "centerStudents") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  //!------------------ Columns --------------------

  const codesColumns = [
    {
      title: "numbering",
      dataIndex: "n",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "serial",
      dataIndex: "key",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Center",
      dataIndex: "center",
      filteredValue: state.filteredInfo.center || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("center", "center"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.center.length - b.center.length,
      sortOrder:
        state.sortingOptions.columnKey === "center" &&
        state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      filteredValue: state.filteredInfo.price || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("price", "Price"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.price - b.price,
      sortOrder:
        state.sortingOptions.columnKey === "price" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const groupsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Group name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Group name"),
      editTable: true,
      width: 150,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Description",
      dataIndex: "description",
      filteredValue: state.filteredInfo.description || null,
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),

      //responsive: ["lg"],
      ...getColumnSearchProps("description", "description"),
      editTable: true,
      width: 180,
      align: "center",
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder:
        state.sortingOptions.columnKey === "description" &&
        state.sortingOptions.order,
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_update")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const QuestionBankColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Category name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Category name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "Easy"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "Medium"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "Hard"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "Number of essay questions",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "Number of essay questions"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "Control"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit   border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                Add essay questions
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit   border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                Add choose questions
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const StudentQuestionBankColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "التحكم"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit   border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                اضافة اسئلة مقالية
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit   border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                اضافة اسئلة اختيارية
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "عدد الاسئلة المقالية",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "عدد الاسئلة المقالية"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "difficult"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "medium"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "Easy"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },

    {
      title: "اسم التصميف",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم التصميف"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const AttendanceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Group name",
      dataIndex: "groupname",
      filteredValue: state.filteredInfo.groupname || null,

      ...getColumnSearchProps("groupname", "Group name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.groupname.length - b.groupname.length,
      sortOrder:
        state.sortingOptions.columnKey === "groupname" &&
        state.sortingOptions.order,
    },
    {
      title: "Classroom",
      dataIndex: "academiclevel",
      width: 150,
      align: "center",
      editTable: true,
      sorter: (a, b) => a.academiclevel.length - b.academiclevel.length,
      sortOrder:
        state.sortingOptions.columnKey === "academiclevel" &&
        state.sortingOptions.order,
      filters: [
        { text: "First grade", value: "First grade" },
        { text: "Second grade", value: "Second grade" },
        { text: "Third grade", value: "Third grade" },
      ],
      filteredValue: state.filteredInfo.academiclevel || null,
      onFilter: (value, record) => String(record.academiclevel).includes(value),
      render: (tag) => {
        const color = tag.includes("First")
          ? "#4285f4"
          : tag.includes("Second")
            ? "#f9b522"
            : "#1890ff";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
  ];

  const assistantsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      /**
      *  ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      */
      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Picture",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-28 w-28 overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancel"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Do you really want to delete Assistant data?"
            >
              <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                Delete
              </button>
            </Popconfirm>

            <button
              className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              onClick={() =>
                navigate(`/admin/assistants/edit-assistant/${record.key}`)
              }
            >
              Edit
            </button>
          </div>
        ) : null;
      },
    },
  ];
  const materialsColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_addmatrial")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Do you really want to delete the file?"
              >
                <button className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            <a
              className="w-fit px-2   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              href={record.address}
              rel="noreferrer"
              target="_blank"
            >
              download
            </a>
          </div>
        ) : null;
      },
    },

    {
      title: "Status",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <p>
          {record.is_public === 1
            ? "Free"
            : record.is_public === 0
              ? "Paid"
              : ""}
        </p>
      ),
    },

    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const sessionAttendanceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.mobile - b.mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      filteredValue: state.filteredInfo.parent_mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("parent_mobile", "Father's phone 1"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.parent_mobile - b.parent_mobile,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("parent_mobile2", "Father's phone 2"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.parent_mobile2 - b.parent_mobile2,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile2" &&
        state.sortingOptions.order,
    },
    {
      title: "Quiz degree",
      dataIndex: "quiz_degree",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.quiz_degree || null,
      ...getColumnSearchProps("quiz_degree", "Quiz degree"),
      editTable: false,
    },
    {
      title: "Student's degree",
      dataIndex: "student_quiz_degree",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.student_quiz_degree || null,
      ...getColumnSearchProps("student_quiz_degree", "Student's degree"),
      editTable: true,
      render: (_, record) => (
        <div className="h-full w-full">
          {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_view")
          ) ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_viewnumberofstudent")
          ) ? (
            <div
              className="flex h-16 w-full cursor-pointer select-none items-center justify-center border-2 border-secondary"
              onDoubleClick={() =>
                record.quiz_degree === null
                  ? null
                  : handleQuizDoubleClicked(record)
              }
            >
              {record.student_quiz_degree}
            </div>
          ) : (
            <div className="cursor-pointer select-none ">
              {record.student_quiz_degree}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Assignment",
      dataIndex: "student_quiz_degree",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div className=" flex h-auto  w-full flex-col items-center  justify-start gap-2">
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 1 && "bg-green-500 text-light"
            }`}
            htmlFor={`yes_${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="1"
              id={`yes_${record.key}`}
              defaultChecked={record.homework === 1}
            />
            <span>Yes</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 0 && "bg-secondary text-light"
            }`}
            htmlFor={`no${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="0"
              id={`no${record.key}`}
              defaultChecked={record.homework === 0}
            />
            <span>No</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 2 && "bg-accent text-light"
            }`}
            htmlFor={`not_Completed${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="2"
              id={`not_Completed${record.key}`}
              defaultChecked={record.homework === 2}
            />
            <span>Not completed</span>
          </label>
        </div>
      ),
    },
    {
      title: "Attendance date",
      dataIndex: "attend_at",
      width: 170,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,

      ...getColumnSearchProps("attend_at", "Attendance date"),
      editTable: false,
    },
    {
      title: "Card number",
      dataIndex: "magnetic_id",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.magnetic_id || null,

      ...getColumnSearchProps("magnetic_id", "Card number"),
      editTable: false,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 90,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <Popconfirm
            okText="Confirm"
            okType="danger"
            cancelText="Cancel"
            color="#fecaca"
            //okButtonProps={{ loading: state.loading }}
            onConfirm={() => handleDelete(record)}
            title="Do you really want to delete?"
          >
            <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
              Delete
            </button>
          </Popconfirm>
        ) : null;
      },
    },
  ];
  const studentHistoryColumns = [
    {
      title: "Attendance date",
      dataIndex: "attend_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,
      ...getColumnSearchProps("attend_at", "Attendance date"),
      editTable: false,
    },
    {
      title: "المكان",
      dataIndex: "location",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.location || null,
      ...getColumnSearchProps("location", "المكان"),
      editTable: false,
    },
    {
      title: "اسم المحاضرة",
      dataIndex: "lecture_name",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.lecture_name || null,
      ...getColumnSearchProps("lecture_name", "اسم المحاضرة"),
      editTable: false,
    },
  ];
  const onlineStudentsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      ...getColumnSearchProps("parent_mobile2", "Phone number"),
      editTable: false,
    },

    {
      title: "City",
      dataIndex: "city",
      filteredValue: state.filteredInfo.city || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("city", "City"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.city.length - b.city.length,
      sortOrder:
        state.sortingOptions.columnKey === "city" && state.sortingOptions.order,
    },
    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("school", "School"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.school.length - b.school.length,
      sortOrder:
        state.sortingOptions.columnKey === "school" &&
        state.sortingOptions.order,
    },
    {
      title: "Attendance card",
      dataIndex: "magnetic_id",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.magnetic_id || null,

      ...getColumnSearchProps("magnetic_id", "Attendance card"),
      editTable: false,
    },
    {
      title: "Added by",
      dataIndex: "approved_by",
      filteredValue: state.filteredInfo.approved_by || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("approved_by", "Added by"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.approved_by.length - b.approved_by.length,
      sortOrder:
        state.sortingOptions.columnKey === "approved_by" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.created_at || null,

      ...getColumnSearchProps("created_at", "Creation date"),
      editTable: false,
    },
    // {
    //   title: "Picture",
    //   dataIndex: "",
    //   width: 100,
    //   align: "center",
    //   render: (_, record) => (
    //     <div className="profile_pic h-28 w-28 overflow-hidden rounded-full border-2 border-secondary p-1">
    //       <img
    //         className="h-full w-full rounded-full object-cover object-top"
    //         src={record.profile_pic}
    //         alt={record.name}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="هل تريد بالفعل حذف بيانات الطالب"
              >
                <button className="w-fit px-2 border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleBlock(record)}
                title="Do You Want Block this Student ?"
              >
                <button className="w-fit px-2 border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Block
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_edit")
              )) && (
              <button
                className="w-fit px-2 border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                onClick={() =>
                  navigate(`/admin/online-students/edit-student/${record.key}`)
                }
              >
                Edit
              </button>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_view")
              )) && (
              <button
                className="w-fit px-2 border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                onClick={() =>
                  navigate(`/admin/online-students/view-student/${record.key}`)
                }
              >
                Show
              </button>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const centerStudentsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      ...getColumnSearchProps("parent_mobile2", "Phone number"),
      editTable: false,
    },

    {
      title: "City",
      dataIndex: "city",
      filteredValue: state.filteredInfo.city || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("city", "City"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.city.length - b.city.length,
      sortOrder:
        state.sortingOptions.columnKey === "city" && state.sortingOptions.order,
    },
    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("school", "School"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.school.length - b.school.length,
      sortOrder:
        state.sortingOptions.columnKey === "school" &&
        state.sortingOptions.order,
    },
    {
      title: "Group",
      dataIndex: "group",
      filteredValue: state.filteredInfo.group || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("group", "Group"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.group.length - b.group.length,
      sortOrder:
        state.sortingOptions.columnKey === "group" &&
        state.sortingOptions.order,
    },
    {
      title: "Attendance card",
      dataIndex: "magnetic_id",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.magnetic_id || null,

      ...getColumnSearchProps("magnetic_id", "Attendance card"),
      editTable: false,
    },
    {
      title: "Added by",
      dataIndex: "approved_by",
      filteredValue: state.filteredInfo.approved_by || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("approved_by", "Added by"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.approved_by.length - b.approved_by.length,
      sortOrder:
        state.sortingOptions.columnKey === "approved_by" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.created_at || null,

      ...getColumnSearchProps("created_at", "Creation date"),
      editTable: false,
    },
    // {
    //   title: "Picture",
    //   dataIndex: "",
    //   width: 100,
    //   align: "center",
    //   render: (_, record) => (
    //     <div className="profile_pic h-28 w-28 overflow-hidden rounded-full border-2 border-secondary p-1">
    //       <img
    //         className="h-full w-full rounded-full object-cover object-top"
    //         src={record.profile_pic}
    //         alt={record.name}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="هل تريد بالفعل حذف بيانات الطالب"
              >
                <button className="w-fit px-2 border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleBlock(record)}
                title="Do You Want Block this Student ?"
              >
                <button className="w-fit px-2 border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Block
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_edit")
              )) && (
              <button
                className="w-fit px-2 border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                onClick={() =>
                  navigate(`/admin/center-students/edit-student/${record.key}`)
                }
              >
                Edit
              </button>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_view")
              )) && (
              <button
                className="w-fit px-2 border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                onClick={() =>
                  navigate(`/admin/center-students/view-student/${record.key}`)
                }
              >
                Show
              </button>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const homeworkSetsColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "difficult"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const quizSetsColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "difficult"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const examSetsColumns = [
    {
      title: "number",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Category Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "Medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "Hard"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancle"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm Delete Category ?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancle"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancle?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    Cancle
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const sessionAbsenceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      ...getColumnSearchProps("parent_mobile2", "Phone number"),
      editTable: false,
    },
  ];

  const lecturesColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "student's name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,
      ...getColumnSearchProps("student_name", "student name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Student Number",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Student Number"),
      width: 200,
      // fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Purchased via",
      dataIndex: "bought_by",
      width: 200,
      align: "center",
    },
    {
      title: "Views",
      dataIndex: "views",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "lecture Expire At",
      dataIndex: "expire_at",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Assignment degree",
      dataIndex: "homework_degree",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.std_homework_degree &&
              `${record.std_homework_degree}/${record.homework_degree}`}
          </p>
        );
      },
    },
    {
      title: "Exam degree",
      dataIndex: "quiz_degree",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.std_quiz_degree &&
              `${record.std_quiz_degree}/${record.quiz_degree}`}
          </p>
        );
      },
    },
    {
      title: "Options",
      dataIndex: "",
      width: 250,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20   border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20   border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20   border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const purchaseHistoryColumns = [
    {
      title: "Lecture name",
      dataIndex: "lecture_name",
      filteredValue: state.filteredInfo.lecture_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("lecture_name", "Lecture name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "lecture_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Exam",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Exam"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,

      render: (_, record) => {
        return (
          <p>
            {record.student_quiz &&
              `${record.quiz_degree}/${record.student_quiz}`}
            {}
          </p>
        );
      },
    },
    {
      title: "Assignment",
      dataIndex: "homework_student",
      filteredValue: state.filteredInfo.homework_student || null,
      ...getColumnSearchProps("homework_student", "Assignment"),
      width: 100,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "homework_student" &&
        state.sortingOptions.order,

      render: (_, record) => {
        return (
          <p>
            {record.is_center === 1 && `${record.homework_student}`}
            {record.homework_student &&
              record.is_center === 0 &&
              `${record.student_quiz}/${record.homework_degree}`}
            {}
          </p>
        );
      },
    },
    {
      title: "Attendance place",
      dataIndex: "location",

      ...getColumnSearchProps("location", "Attendance place"),
      width: 120,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "location" &&
        state.sortingOptions.order,
    },
    {
      title: "Date",
      dataIndex: "time",
      filteredValue: state.filteredInfo.time || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("time", "Date"),
      width: 120,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "time" && state.sortingOptions.order,
    },
  ];

  const viewStudentExamColumns = [
    {
      title: "Number",
      dataIndex: "n",
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      ...getColumnSearchProps("n", "n"),
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("student_name", "student_name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.student_name.length - b.student_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Mobile"),
      width: 150,
      align: "center",
    },

    {
      title: "Parent Mobile 1",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
    },
    {
      title: "Parent Mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
    },

    {
      title: "Degree",
      dataIndex: "degree",
      width: 150,
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      width: 150,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: 150,
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "",
      width: 360,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 w-full flex  justify-center gap-4">
            {record.degree !== null && (
              <Link to={`/admin/exams/all-students/${record.key}/model-answer`}>
                <button className="w-60   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Model Answer
                </button>
              </Link>
            )}

            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancle"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleRetakeExam(record)}
              title="Confirm retake?"
            >
              <button className="w-60   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                Retake Exam
              </button>
            </Popconfirm>
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancle"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Confirm delete action?"
            >
              <button className="px-4   border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                Delete
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
  ];
  //!----------Nested Table for attendance page

  const expandedRow = (row) => {
    const nestedSessionColumns = [
      {
        title: "Class name",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "Class name"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "left",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
      {
        title: "Number of students",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "Number of students"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "Attendance",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px]   border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              Show
            </Link>
          );
        },
      },
    ];

    const nestedLecturesColumns = [
      {
        title: "Attendance",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px]   border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              Show
            </Link>
          );
        },
      },
      {
        title: "Number of students",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "Number of students"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "Class name",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "Class name"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "left",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
    ];

    let sessionData;
    let rowKey = row.n - 1;
    sessionData = state.data[rowKey].sessions;

    return (
      <Table
        columns={
          tableFor === "lectures" ? nestedLecturesColumns : nestedSessionColumns
        }
        dataSource={sessionData}
        pagination={false}
      />
    );
  };

  switch (tableFor) {
    case "codes":
      columns = codesColumns;
      break;
    case "groups":
      columns = groupsColumns;
      break;
    case "QuestionBank":
      columns = QuestionBankColumns;
      break;
    case "studentQuestionBank":
      columns = StudentQuestionBankColumns;
      break;
    case "attendance":
      columns = AttendanceColumns;
      break;

    case "assistants":
      columns = assistantsColumns;
      break;
    case "onlineStudents":
      columns = onlineStudentsColumns;
      break;
    case "centerStudents":
      columns = centerStudentsColumns;
      break;
    case "sessionAttendance":
      columns = sessionAttendanceColumns;
      break;
    case "sessionAbsence":
      columns = sessionAbsenceColumns;
      break;
    case "studentHistory":
      columns = studentHistoryColumns;
      break;
    case "materials":
      columns = materialsColumns;
      break;
    case "homeworkSets":
      columns = homeworkSetsColumns;
      break;
    case "quizSets":
      columns = quizSetsColumns;
      break;
    case "examSets":
      columns = examSetsColumns;
      break;
    case "lectures":
      columns = lecturesColumns;
      break;
    case "purchaseHistory":
      columns = purchaseHistoryColumns;
      break;
    case "viewStudentExam":
      columns = viewStudentExamColumns;
      break;

    default:
      columns = codesColumns;
      break;
  }
  const editableColumns = columns.map((col) => {
    if (!col.editTable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // set sellection in table
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch({ type: "setSelectedRows", payload: selectedRows });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //! buttons functions
  const handelSendAbsence = () => {
    let finalData = {
      lecture_id: params.lectureID,
      group_id: params.groupID,
      selected: state.selectedRows,
    };
    async function fetchData() {
      try {
        let res = await axios.post(
          process.env.REACT_APP_ADMIN_SEND_ABSENCE_API,
          finalData,
          {
            //TODO: FIX THIS
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.userData).admin_token
              }`,
            },
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    fetchData();
  };

  const table = (
    <section>
      <div className="table-options  flex flex-row-reverse items-center justify-between gap-10 md:flex-col md:items-center">
        <div className="export-reset flex w-1/2 items-center justify-end gap-10 md:w-full md:flex-col md:items-center">
          {state?.data && (
            <button className="w-fit border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
              <CSVLink data={state?.data} filename={`${tableFor} Excel.csv`}>
                Export
              </CSVLink>
            </button>
          )}
          <button
            className="w-fit border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            onClick={handleResetSortFilterSearch}
          >
            Reset the table
          </button>
        </div>
        <div className="search-wrapper w-1/2 md:w-full">
          <input
            dir="ltr"
            type="search"
            className="signin-inputs my-7 pl-4 "
            placeholder="Search..."
            onChange={handleGlobalSearch}
            value={state.query}
          />
        </div>
      </div>

      <div className=" w-full  overflow-hidden rounded-xl border-2  border-secondary">
        <Form form={form} component={false}>
          <Table
            columns={editableColumns}
            dataSource={state.data}
            components={{ body: { cell: editableCell } }}
            bordered
            onChange={handleTableChange}
            loading={state.loading}
            expandedRowRender={nested ? expandedRow : ""}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            pagination={{ position: ["bottomCenter"] }}
            // rowSelection={{
            //   type: "checkbox",
            //   onSelect: (record) => console.log(record),
            //   selectedRowKeys: state.selectedRows,
            //   onChange: (keys) => {
            //     dispatch({
            //       type: "setSelectedRows",
            //       payload: keys,
            //     });
            //   },
            //   selections: [
            //     Table.SELECTION_ALL,
            //     Table.SELECTION_NONE,
            //     Table.SELECTION_INVERT,
            //     {
            //       key: "even",
            //       text: "Select Even Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 === 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "odd",
            //       text: "Select Odd Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 !== 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "First Level",
            //       text: "Select First Level Students",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter((key) => {
            //           const matched = state.data.find((item) => {
            //             return (
            //               item.key === key && item.academiclevel === "First"
            //             );
            //           });
            //           return matched;
            //         });
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //   ],
            // }}
            scroll={{
              y: 540,
            }}

            /**expandable={{
              expandedRowRender: (record) => <p> {record.school}</p>,
              rowExpandable: (record) => record.school !== "Not Expandable",
            }}*/
          />
        </Form>
      </div>
      {tableFor === "sessionAbsence" && (
        <div className="mt-4 flex w-1/2 items-center justify-start gap-10 md:w-full md:flex-col md:items-center">
          <button
            className="w-fit   border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            disabled={state.selectedRows.length <= 0}
            onClick={handelSendAbsence}
          >
            Sending absences to parents
          </button>
        </div>
      )}
    </section>
  );
  return table;
}

export default useTable;
